.header{
    height: 80px;
    display: flex;
    gap: 12px;
    align-items: center;
    padding-right: 12px;
    padding-left: 17px;
    box-sizing: border-box;  
    /* border-bottom: 0.5px solid #000; */
    background: #F5F1E5; 
}
.logo{
    width: 102px;
    height: 31px;
}
.slogan{
    color: #000;
    font-family: Roobert;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align:left;
}
