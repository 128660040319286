.card{
    opacity: 0.95;
}
.cardBorder{
    border-right: 0.5px solid #000;
    border-bottom: 0.5px solid #000;
    box-sizing: border-box;
}
.cardDescr{
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    border-top: 0.5px solid #000;
    background: #FFF;
}
.brandName{
    color: #000;
    font-family: Roobert;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.iconsControl{
    display: flex;
    gap: 15px;
    cursor: pointer;
}